<template>
  <div class="edit">
    <baseContainer submit cancel @submit="submit">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
        <baseSection name="修改密码">
          <baseRow justify="start">
            <baseCol>
              <el-form-item label="原密码" prop="oldPassword">
                <el-input type="password" v-model.trim="form.oldPassword" placeholder="请输入原密码" readonly onfocus="this.removeAttribute('readonly');"></el-input>
              </el-form-item>
            </baseCol>
          </baseRow>
          <baseRow justify="start">
            <baseCol>
              <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model.trim="form.newPassword" placeholder="请输入新密码" readonly onfocus="this.removeAttribute('readonly');"></el-input>
              </el-form-item>
            </baseCol>
          </baseRow>
          <baseRow justify="start">
            <baseCol>
              <el-form-item label="确认密码" prop="rePassword">
                <el-input type="password" v-model.trim="form.rePassword" placeholder="请再次输入密码" readonly onfocus="this.removeAttribute('readonly');"></el-input>
              </el-form-item>
            </baseCol>
          </baseRow>
        </baseSection>
      </el-form>
    </baseContainer>
  </div>
</template>

<script>
import baseContainer from '@/components/container/base-container';
import baseSection from '@/components/section/base-section';
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.rePassword !== '') {
          this.$refs.ruleForm.validateField('repassword');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        rePassword: ''
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [{ required: true, validator: validatePass, trigger: 'blur' }],
        rePassword: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      }
    };
  },
  methods: {
    async submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const { fail } = await this.$apis.sysUser.updatePassword(this.form);
          if (!fail) {
            for (const key in this.form) {
              this.form[key] = '';
            }
          }
        }
      });
    }
  },
  components: {
    baseContainer,
    baseSection,
    baseRow,
    baseCol
  }
};
</script>

<style lang="scss" scoped></style>
